import React, { useMemo, useState } from 'react';
import Image from 'next/image';
import { Carousel, CarouselProps, Image as AntdImage } from 'antd';
import { getStorePortfolio } from '@/shared/utils/store-helper';
import type { Store } from '@/shared/types/store.interface';
import { imgix, isVideo } from '@/shared/utils/utils';
import { useWindowSize } from 'react-use';
import { isEmpty } from 'lodash';
import './index.less';

const Gallery = ({
  store,
  onDisableGallery,
  isMobile,
}: {
  store: Store;
  onDisableGallery?: () => void;
  isMobile?: boolean;
}) => {
  const columns = { small: 2, medium: 3, large: 5 };
  const { width } = useWindowSize();
  const [visible, setVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const SamplePrevArrow = ({ slideCount, currentSlide, ...props }: any) => (
    <div {...props} data-slide-count={slideCount} data-current-slide={currentSlide}>
      <Image src="/images/white-left.svg" alt="prevArrow" width={20} height={20} />
    </div>
  );

  const SampleNextArrow = ({ slideCount, currentSlide, ...props }: any) => (
    <div {...props} data-slide-count={slideCount} data-current-slide={currentSlide}>
      <Image src="/images/white-right.svg" alt="prevArrow" width={20} height={20} />
    </div>
  );

  const setCarouselSlideWidth = useMemo(() => {
    if (width > 980) {
      return width / columns.large;
    } else if (width > 600 && width <= 980) {
      return width / columns.medium;
    } else {
      return width / columns.small;
    }
  }, [columns.large, columns.medium, columns.small, width]);

  const getSlidesToShow = useMemo((): number => {
    if (width > 980) {
      return columns.large;
    }
    if (width > 600 && width <= 980) {
      return columns.medium;
    }
    return columns.small;
  }, [columns.large, columns.medium, columns.small, width]);

  const carouselSettings = useMemo(
    (): CarouselProps => ({
      dots: false,
      infinite: true,
      accessibility: true,
      arrows: !isMobile,
      autoplay: true,
      speed: 500,
      slidesToShow: getSlidesToShow,
      slidesToScroll: 1,
      className: 'gallery-carousel',
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }),
    [getSlidesToShow, isMobile],
  );

  const photos = useMemo((): string[] => {
    const _photos: string[] = [];
    _photos.push.apply(_photos, getStorePortfolio(store));
    const pics = _photos.filter((url: string) => !isVideo(url));
    if (isEmpty(pics)) {
      onDisableGallery?.();
      return [];
    }
    return pics;
  }, [onDisableGallery, store]);

  return (
    <div className="designer-gallery">
      <AntdImage.PreviewGroup
        items={photos}
        preview={{
          visible,
          onVisibleChange: () => {
            setVisible(false);
          },
          onChange: (_current) => {
            setCurrent(_current);
          },
          current,
        }}
      >
        <Carousel {...carouselSettings}>
          {photos.map((photo, index) => (
            <div
              key={`photo-item-${index}`}
              className="slide"
              onClick={() => {
                setVisible(true);
                setCurrent(index);
              }}
            >
              <Image
                alt="photo"
                src={imgix(photo, { h: 250, dpr: 2, q: 100 })}
                width={setCarouselSlideWidth}
                height={250}
              />
            </div>
          ))}
        </Carousel>
      </AntdImage.PreviewGroup>
    </div>
  );
};

export default Gallery;
