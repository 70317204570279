import React from 'react';
import Link from 'next/link';
import { imageTransform } from '@/shared/utils/utils';
import classNames from 'classnames';
import { useConfigProvider } from '@/lib/context/ConfigProvider';
import { logCommonView } from '@/lib/service';
import { EventSource } from '@/shared/types/event-types';
import Image from 'next/image';
import './index.less';

interface Props {
  avatarUrl: string;
  goToUrl: string;
  direction?: string;
  text: string;
  storeId: number;
}

const TooltipAvatar = ({ avatarUrl, goToUrl, storeId, direction, text }: Props) => {
  const { isMobile } = useConfigProvider();
  const logClick = () => {
    logCommonView(EventSource.PRODUCT_GRID_ITEM_SHOP_AVATAR, storeId);
  };

  return (
    <div className="tooltip-avatar">
      <Link href={goToUrl} className="avatar" onClick={logClick}>
        {avatarUrl && (
          <Image
            width={42}
            height={42}
            src={imageTransform(avatarUrl, 'compact')}
            alt="Creator avatar"
            title="Creator avatar"
          />
        )}
      </Link>
      {!isMobile && (
        <div className="tooltip-element">
          <span className={classNames('tooltip', direction)}>
            <Link href={goToUrl} className="go-to-shop">
              {text}
            </Link>
          </span>
          <i className="arrow"></i>
        </div>
      )}
    </div>
  );
};

export default TooltipAvatar;
