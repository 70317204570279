import { Button, Input, Modal } from 'antd';
import Image from 'next/image';
import classNames from 'classnames';
import { imageTransform, isEmailValid } from '@/shared/utils/utils';
import { CSSProperties, useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { useAsyncFn } from 'react-use';
import { subscribe } from '@/lib/service';
import './index.less';

const EmailSignup = ({
  open,
  storeId,
  className,
  signupMeta = {},
  onCancel,
  isMobile,
}: {
  open?: boolean;
  storeId: number;
  signupMeta?: {
    backgroundColor?: string;
    imageBorder?: boolean;
    imageUrl?: string;
    imageAlt?: string;
    subtitle?: string;
    signupConfirmationText?: string;
    title?: { style: CSSProperties; text: string }[];
  };
  onCancel?: () => void;
  onOk?: (value: string) => void;
  className?: string;
  isMobile?: boolean;
}) => {
  const [email, setEmail] = useState<string>();
  const [step, setStep] = useState<number>(1);
  const [subscribeState, postSubscribeAsync] = useAsyncFn(subscribe);

  const submitEmailSignup = useCallback(async () => {
    if (isEmpty(email) || !isEmailValid(email)) {
      return null;
    }
    try {
      await postSubscribeAsync(storeId, { meta: { email } });
      setStep(2);
    } catch (e) {}
  }, [email, postSubscribeAsync, storeId]);

  return (
    <Modal
      centered
      closable={false}
      title={null}
      styles={{
        body: { padding: 0 },
      }}
      footer={null}
      open={open}
      width={isMobile ? '90%' : 700}
      onCancel={() => {
        setStep(1);
        onCancel?.();
      }}
      className={classNames('email-signup', className)}
    >
      <div className="dialog-inner" style={{ backgroundColor: signupMeta.backgroundColor }}>
        <div className="inner">
          <div className="left">
            <div
              className={classNames('image', {
                'has-border': signupMeta.imageBorder,
              })}
            >
              <Image
                fill
                src={imageTransform(signupMeta.imageUrl, 'large')}
                alt={signupMeta.imageAlt}
                title={signupMeta.imageAlt}
              />
            </div>
          </div>
          {step === 1 && (
            <div className="right">
              <span className="title">
                {(signupMeta.title || []).map((title, index) => (
                  <span key={`title-${index}`} style={title.style}>
                    {title.text}
                  </span>
                ))}
              </span>
              <span className="subtitle">{signupMeta.subtitle}</span>
              <Input
                type="email"
                className="email-input-field"
                placeholder="Add your e-mail"
                onChange={(e) => setEmail(e.target.value || '')}
              />
              <Button
                size={'large'}
                loading={subscribeState.loading}
                className="action-button utility-big"
                onClick={submitEmailSignup}
              >
                Submit
              </Button>
              <div className="no-thanks utility-big" onClick={onCancel}>
                No thanks
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="right" id="email-signup-confirmed">
              <span className="thanks">Thanks!</span>
              <span>{signupMeta.signupConfirmationText}</span>
              <div
                className="action-button utility-big"
                style={{ marginTop: '2rem' }}
                onClick={() => {
                  setStep(1);
                  onCancel?.();
                }}
              >
                Keep Browsing
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EmailSignup;
