import classNames from 'classnames';
import { ImgWithFallback, EmailSignup } from '@/components/Common';
import { Gallery, Products } from '@/components/Designers';
import { PIETRA_LOGO_STONE } from '@/shared/constants/app';
import { TopBanner } from '@/components/Shop';
import { Typography } from 'antd';
import { isStorePasswordProtected } from '@/shared/utils/store-helper';
import { getTagColor } from '@/shared/utils/marketplace';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import type { IProduct } from '@/shared/types/product-types.interface';
import type { Store } from '@/shared/types/store.interface';
import getConfig from 'next/config';
import Link from 'next/link';
import './index.less';
import { hasUnlockedPlatform } from '@/shared/utils/utils';

const { Paragraph } = Typography;
const DesignerProfile = ({
  className,
  store,
  isMobile,
  products,
}: {
  className?: string;
  store: Store;
  products: IProduct[];
  isMobile: boolean;
}) => {
  const { publicRuntimeConfig } = getConfig();
  const [bioExpanded, setBioExpanded] = useToggle(false);
  const [showEmailCapture, showEmailCaptureFunc] = useToggle(false);
  const [showGallery, setShowGallery] = useState<boolean>(true);
  const hasBannerText = useMemo(() => {
    return !isEmpty(store.bannerText);
  }, [store.bannerText]);

  const designerTags = useMemo(() => store?.tags ?? [], [store?.tags]);

  return (
    <div className={classNames('designer-full-profile', className)}>
      {hasBannerText && (
        <TopBanner bannerText={store.bannerText} color={'#000'} textColor={'#fff'} />
      )}

      {!store.isStorefrontProtected && showGallery && (
        <div className="gallery">
          <Gallery
            store={store}
            isMobile={isMobile}
            onDisableGallery={() => setShowGallery(false)}
          />
        </div>
      )}

      <div className="content">
        <div className="designer-information">
          <div className="designer-primary-information">
            <div className="avatar-container">
              <ImgWithFallback
                className="avatar"
                src={store.avatarUrl ?? PIETRA_LOGO_STONE}
                alt="avatar"
                width={300}
                height={300}
              />
            </div>
            <div className="info-container">
              <div className="inline">
                <span className="name">{store.title || '--'}</span>
              </div>
              <div className="description">
                <Paragraph
                  ellipsis={
                    !bioExpanded
                      ? {
                          rows: isMobile ? 2 : 3,
                          suffix: (store.ourStory || '').length > 230 ? ' (more)' : '',
                        }
                      : !bioExpanded
                  }
                  onClick={() => {
                    setBioExpanded(!bioExpanded);
                  }}
                >
                  {store.ourStory || ''}
                </Paragraph>
              </div>

              {!isStorePasswordProtected(store) && (
                <div className="buttons-and-tags">
                  {designerTags.map((tag, index) => (
                    <span
                      key={`tag-item-${index}`}
                      className="tag"
                      style={{ backgroundColor: getTagColor(tag) }}
                    >
                      {tag}
                    </span>
                  ))}
                  <span className="action-button" onClick={showEmailCaptureFunc}>
                    Join email list
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Products products={products} isMobile={isMobile} />
      </div>
      <div className="footer">
        {!hasUnlockedPlatform(store) && (
          <>
            <span>Powered By </span>
            <Link href={`${publicRuntimeConfig?.NEXT_CREATOR_HUB ?? '/'}`}>Pietra</Link>
          </>
        )}
      </div>
      <EmailSignup
        open={showEmailCapture}
        storeId={store.id}
        className="designer-subscribe"
        signupMeta={{
          title: [{ text: `Never miss an update from ${store.title}`, style: {} }],
          subtitle: `You'll get notified when ${store.title} releases new collections and has shop promotions.`,
          backgroundColor: '#DEDBD6',
          imageUrl:
            store.avatarUrl ||
            'https://static.pietrastudio.com/public/file_uploads/8d5f6084a543f497e9bcc6b035c0b3f4.png',
          imageAlt: `Sign up for ${store.title}'s email list.`,
          imageBorder: true,
          signupConfirmationText: 'Welcome to ' + store.title + "'s community. We'll be in touch!",
        }}
        onCancel={showEmailCaptureFunc}
      />
    </div>
  );
};
export default DesignerProfile;
