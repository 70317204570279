import axios from '@/shared/utils/axios';
import { queryStoreDetailParams, Store } from '@/shared/types/store.interface';

export async function subscribe(storeId: number, meta: { [key: string]: any }) {
  const res = await axios.post('/app/stores/' + storeId + '/subscription', meta);
  return res.data;
}
export async function getAllStores(params: { [key: string]: any }) {
  const res = await axios.get<any>('/app/stores', { params });
  return res.data;
}

export async function getStoreDetail(storeId: number, params: queryStoreDetailParams) {
  const res = await axios.get<any>(`/app/stores/${storeId}`, { params });
  return res.data;
}

export async function getStoreDetailWithV1Id(v1Id: number) {
  const res = await axios.get<any>(`/app/stores/${v1Id}`);
  return res.data;
}

export async function getStoreListByHandles(storeList: string[], params: { [key: string]: any }) {
  const res = await axios.get<any>('/app/stores/action/handle/' + storeList.join(','), { params });
  return res.data;
}

export async function getStoreDetailsWithHandle(handle?: string, params?: { [key: string]: any }) {
  const res = await axios.get<Store>('/app/stores/action/handle/' + handle, { params });
  return res.data;
}
