import React, { useCallback, useMemo } from 'react';
import { TooltipAvatar } from '@/components/Common';
import type { IProduct } from '@/shared/types/product-types.interface';
import { formatPrice, imageTransform } from '@/shared/utils/utils';
import { Typography } from 'antd';
import { EventSource } from '@/shared/types/event-types';
import {
  getItemCoverImage,
  getItemCoverImages,
  getItemMinPrice,
} from '@/shared/utils/store-helper';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { logCommonView } from '@/lib/service';
import './index.less';

const { Paragraph } = Typography;
const ProductItem = ({
  item,
  showVisitShopLink = true,
  isMobile,
  nameMaxLines = 2,
  openNewWindow = false,
}: {
  item: IProduct;
  isMobile?: boolean;
  showVisitShopLink?: boolean;
  openNewWindow?: boolean;
  nameMaxLines?: number;
}) => {
  const router = useRouter();
  const itemCoverImage2 = useMemo(() => {
    const images = getItemCoverImages(item);
    return images.length > 1 ? images[1] : '';
  }, [item]);

  const showSecondImage = useMemo(() => {
    return !isMobile && itemCoverImage2.length > 0;
  }, [isMobile, itemCoverImage2.length]);

  const itemMinPrice = useMemo(() => {
    const price = getItemMinPrice(item);
    if (price > 0) {
      return formatPrice(Number.parseFloat(price).toFixed(2));
    }
    return '';
  }, [item]);

  const isSoldOut = useMemo(() => {
    return item.syncData && !item?.syncData?.availableForSale && item.isEnabled;
  }, [item.isEnabled, item.syncData]);

  const handleClick = useCallback(() => {
    let route = '/shop/' + item.id;
    if (router.pathname === '/shop/bridal') {
      route += '?customizeOnly';
    }
    const content = `productId: ${item.id}, storeId: ${item.store.id}, originPath: ${router.pathname}, destinationPath: /shop/${item.id}`;
    logCommonView(EventSource.CREATOR_SHOP_PRODUCT_ITEM, content);
    if (openNewWindow) {
      window.open(route, '_blank').focus();
    } else {
      router.push(route);
    }
  }, [item.id, item.store.id, openNewWindow, router]);

  return (
    <div className="product-grid-item">
      {showVisitShopLink && item.store.avatarUrl && (
        <TooltipAvatar
          avatarUrl={imageTransform(item.store.avatarUrl, 'compact')}
          goToUrl={`/s/${item.store.handle}`}
          text={'View Brand'}
          storeId={item.storeId}
        />
      )}
      <div className="inner">
        <div className="default-view">
          <span className="shop-item-image" onClick={handleClick}>
            {showSecondImage && (
              <Image
                src={imageTransform(itemCoverImage2, 'grande')}
                className="image-2"
                fill
                alt={item.name}
                title={item.name}
              />
            )}
            <Image
              fill
              src={imageTransform(getItemCoverImage(item), 'grande')}
              className="image-1"
              alt={item.name}
              title={item.name}
            />
          </span>
          <span className="product-info" onClick={handleClick}>
            <div className="left">
              <span className="name truncate-overflow">{item.store.title}</span>
              <Paragraph className="item-name truncate-overflow" ellipsis={{ rows: nameMaxLines }}>
                {item.name}
              </Paragraph>
              <div className="price-wrapper">
                <span className="price">{itemMinPrice}</span>
              </div>
            </div>
            {isSoldOut && <span className="sold-out-tag">Sold Out</span>}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
