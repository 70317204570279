import type { IProduct } from '@/shared/types/product-types.interface';
import { useCallback, useMemo, useState } from 'react';
import { chunk } from 'lodash';
import { useWindowSize } from 'react-use';
import ProductItem from './Item';
import './index.less';
import { Input } from 'antd';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

const Products = ({ products, isMobile }: { products: IProduct[]; isMobile?: boolean }) => {
  const [searchKey, setSearchKey] = useState<string>('');

  const { width } = useWindowSize();
  const emptyProducts = useMemo(() => {
    const arr: any[] = [];
    arr.push.apply(arr, products);
    arr.push.apply(arr, new Array(4).fill({}));
    return arr;
  }, [products]);

  const productsToShow = useMemo(() => {
    return _.filter(products, (p) => _.lowerCase(p.name).includes(_.lowerCase(searchKey)));
  }, [products, searchKey]);

  const numProductsInRow = useMemo(() => {
    if (width >= 1280) {
      return 4;
    }
    if (width < 1280 && width >= 980) {
      return 3;
    }
    return 2;
  }, [width]);

  const chunkArray = useCallback((arr: any[], size: number) => {
    return chunk(arr, size);
  }, []);

  const renderProductSearch = () => {
    return (
      <div className="product-search-container">
        <Input
          size="large"
          placeholder="Search products"
          value={searchKey}
          prefix={<SearchOutlined />}
          onChange={(evt) => setSearchKey(evt.target.value)}
        />
      </div>
    );
  };

  return (
    <div className="shop-tab-outer">
      {!_.isEmpty(products) ? (
        <>
          {renderProductSearch()}
          <div className="shop-tab-frame">
            {!_.isEmpty(productsToShow) ? (
              chunkArray(productsToShow, numProductsInRow).map((row, index) => (
                <div className="row" key={`row-item-${index}`}>
                  {row.map((item) => (
                    <ProductItem
                      key={`col-item-${item.id}`}
                      item={item}
                      isMobile={isMobile}
                      showVisitShopLink={false}
                    />
                  ))}
                </div>
              ))
            ) : (
              <span className="no-results">We couldn&apos;t find any results for your search.</span>
            )}
          </div>
        </>
      ) : (
        <div className="shop-tab-frame empty">
          <div className="row">
            {emptyProducts.map((_, index) => (
              <div className="empty-product" key={`empty-product-item-${index}`}>
                <div className="product-grid-item empty">Coming Soon</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
