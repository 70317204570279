import axios from '@/shared/utils/axios';
import { ANONYMOUS_USER_ID, CAMPAIGN_DATA, FB_ADVERTISER_ID } from '@/shared/constants/app';
import { EventName, EventSource } from '@/shared/types/event-types';
import {
  IProduct,
  IProductVariant,
  IProductVariantSelectedOption,
} from '@/shared/types/product-types.interface';
import { Store } from '@/shared/types/store.interface';
import _get from 'lodash/get';
import storage from '@/shared/utils/storage';

async function commonLog(params: {
  eventName: string;
  itemId?: number;
  commonType?: EventSource;
  commonContent?: any;
  sumOfItemValue?: any;
  recommId?: string;
}) {
  try {
    // TODO
    const commonParams = {
      ...storage.get(CAMPAIGN_DATA),
      anonymousUserId: storage.get(ANONYMOUS_USER_ID),
      fbAdvertiserId: FB_ADVERTISER_ID,
    };
    return await axios.post('/commons/event-analyses/report', { ...params, ...commonParams });
  } catch (error) {
    console.error(error);
  }
}

export async function logCommonView(type: EventSource, content: any) {
  await commonLog({
    eventName: EventName.COMMON_VIEW,
    commonType: type,
    commonContent: content,
  });
}

function getShopifyVariantId(item: IProduct, selectedVariantTitle: string) {
  const variantItem = item?.skus.find((sku: IProductVariant) => {
    const options = (sku?.selectedOptions ?? []).map(
      (option: IProductVariantSelectedOption) => option.value,
    );
    return options.join(' / ') === selectedVariantTitle;
  });
  return variantItem?.variantId;
}

export async function logItemView(store: Store, item: IProduct) {
  const price = _get(item, 'syncData.variants.edges[0].node.price');
  await commonLog({
    eventName: EventName.PRODUCT_VIEW,
    itemId: item.id,
    sumOfItemValue: price,
    // TODO
    // recommId: this.$store.getters.recommId,
  });
  // TODO
  // this.$store.commit('setRecommId', ''); // recomm id should only be sent once
}

export async function logAddToCart(
  item: IProduct,
  selectedVariant: any,
  sumOfItemValue: string,
  // gtm: any[],
) {
  // const shopifyVariantId = selectedVariant
  //   ? getShopifyVariantId(item, selectedVariant?.title ?? '')
  //   : item?.id;
  // const currencyCode = _get(item, 'syncData.priceRange.maxVariantPrice.currencyCode');

  // gtm.push({
  //   event: 'addToCart',
  //   itemId: shopifyVariantId,
  //   price: sumOfItemValue,
  //   currencyCode,
  // });

  await commonLog({
    eventName: EventName.ADD_TO_CART,
    itemId: item.id,
  });
}
