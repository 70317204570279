import './index.less';
const TopBanner = ({
  bannerText,
  color,
  textColor,
}: {
  bannerText: string;
  color: string;
  textColor: string;
}) => {
  return (
    <div style={{ backgroundColor: color }} className="top-banner utility-small">
      <span style={{ color: textColor ? textColor : '#000' }}>{bannerText}</span>
    </div>
  );
};

export default TopBanner;
