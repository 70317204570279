import { useMemo } from 'react';
import { imageTransform, isVideo } from '@/shared/utils/utils';
import Image from 'next/image';
import classNames from 'classnames';
import './index.less';
const ImgWithFallback = ({
  src,
  webp,
  alt,
  className,
  ...reset
}: {
  src?: string;
  webp?: string;
  size?: string;
  alt?: string;
  className?: string;
  width?: number | `${number}`;
  height?: number | `${number}`;
}) => {
  const urlToUse = useMemo((): string => {
    if (src.includes('public_')) {
      return src;
    }
    return src;
  }, [src]);
  return (
    <div className={classNames('fallback-asset', className)}>
      {useMemo(() => {
        if (isVideo(src)) {
          return (
            <video
              src={`${src}+'#t=0.1'`}
              loop
              preload="true"
              autoPlay
              muted
              playsInline
              {...reset}
            />
          );
        }
        return <Image src={webp || urlToUse} alt={alt || 'image'} {...reset} />;
      }, [alt, reset, src, urlToUse, webp])}
    </div>
  );
};
export default ImgWithFallback;
